import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import AreaAtuacao from '../components/areaatuacao'

const NotFoundPage = () => (
  <Layout>
    <SEO title="Ops, conteúdo não encontrado :(" />

    <section className="bg-white">
      <div className="container-fluid">
        <div className="row">
            <div className="col-12 col-xl-10 offset-xl-1">
                <div className="jumbotron bg-transparent">
                  <h1 className="display-4">Página não encontrada</h1>
                  <p className="lead">Ops, parece que o conteúdo que você estava procurando não foi encontrado</p>
                  <a href="/" title="Ir para o início" className="font-weight-bold">Ir para o início</a>
                </div>
            </div>
        </div>
      </div>
    </section>

    <AreaAtuacao />
  </Layout>
)

export default NotFoundPage
